.container {
  height: 200px;
  width: 60%;
  background-color: aquamarine;
  position: relative;
  left: 300px;
}
.order_Div {
  height: 120px;
  width: 200px;
  background-color: rgb(69, 137, 247);
  text-align: center;
  border-radius: 8px;
  top: 0px;
  position: absolute;
  box-shadow: 5px 5px 5px black;
}
.inventry_Div {
  height: 200px;
  width: 200px;
  background-color: rgb(69, 137, 247);
  text-align: center;
  border-radius: 8px;
  position: absolute;
  top: 0px;
  margin-left: 200px;
  box-shadow: 5px 5px 5px black;
}
.PO_heading {
  padding-top: 60px;
  color: white;
  text-shadow: 2px 2px 2px black;
  font-size: 25px;
}
.products_Div {
  margin-top: 50px;
  width: 100%;
  height: 400px;
  /* background-color: rgb(65, 233, 233); */
}
.item_Div {
  height: 200px;
  width: 200px;
  border: 2px solid;
  border-radius: 5px;
  margin-left: 5px;
  float: left;
  text-align: center;
}
.search_Text {
  height: 35px;
  width: 300px;
  border-radius: 20px;
  text-align: center;
  font-size: 22px;
  font-weight: bold;
}
.enter_Text {
  height: 35px;
  width: 200px;
  border-radius: 20px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
  margin-left: 5px;
}
.entry_Div {
  height: 130px;
  width: 100%;
  background-color: rgb(216, 219, 219);
  margin-top: 10px;
}
button {
  height: 40px;
  width: 200px;
  background-color: blue;
  color: white;
  font-size: 25px;
  font-weight: bold;
  border: none;
  border-radius: 20px;
  margin-top: 10px;
  margin-left: 5px;
}
table {
  margin-top: 5px;
  margin-left: 300px;
  border: 2px solid;
  border-collapse: collapse;
  /* display: none; */
}
td {
  border: 1px solid;
  height: 30px;
}
tr {
  height: 30px;
  border: 1px solid;
}
th {
  border: 1px solid;
  background-color: blue;
  color: white;
  width: 200px;
  font-size: 25px;
}
